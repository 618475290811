"use client";

import { useState, useEffect, useRef } from "react";
import Link from "next/link";
import Image from "next/image";
import { motion, AnimatePresence } from "framer-motion";
import { usePathname } from "next/navigation";
import { PrimaryButton } from "@/components/common/button/ButtonPrimary";

const navLinks = {
  sections: [
    {
      name: "Réalisations",
      href: "/#realisations",
      type: "section",
    },
    {
      name: "Tarifs",
      href: "/#tarifs",
      type: "section",
    },
    {
      name: "Votre projet",
      href: "/#processus",
      type: "section",
    },
    {
      name: "Avis clients",
      href: "/#avis",
      type: "section",
    },
  ],
  pages: [
    {
      name: "Recrutement",
      href: "/recrutement",
      type: "page",
    },
    {
      name: "À propos",
      href: "/a-propos",
      type: "page",
    },
  ],
};

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
  const navbarRef = useRef<HTMLDivElement>(null);
  const pathname = usePathname();

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY === 0);
    };

    const updateTotalHeight = () => {
      if (navbarRef.current) {
        document.documentElement.style.setProperty(
          "--total-header-height",
          `${navbarRef.current.offsetHeight}px`,
        );
      }
    };

    updateTotalHeight();
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateTotalHeight);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateTotalHeight);
    };
  }, []);

  const handleNavigation = (href: string, type: string) => {
    setIsMenuOpen(false);

    if (type === "section" && pathname === "/") {
      const element = document.querySelector(href.replace("/", ""));
      if (element) {
        const offset = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  };

  const menuVariants = {
    closed: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.2,
      },
    },
    open: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const linkVariants = {
    closed: { opacity: 0, x: -20 },
    open: { opacity: 1, x: 0 },
  };

  return (
    <header
      ref={navbarRef}
      className={`sticky top-0 z-50 w-full transition-all duration-300 ${
        isAtTop ? "bg-transparent" : "bg-[#1F1B4B]/30 backdrop-blur-md"
      }`}
    >
      <nav className="mx-auto flex h-16 items-center justify-between px-4 md:px-6 lg:px-8">
        <Link href="/" className="relative h-[49px] w-[100px]">
          <Image
            src="/Next_V_Blanc.png"
            alt="Next Generation"
            fill
            className="object-contain"
            priority
          />
        </Link>

        {/* Desktop Navigation */}
        <motion.div
          className="hidden items-center gap-8 lg:flex"
          initial={false}
        >
          {/* Sections */}
          <div className="flex items-center gap-8">
            {navLinks.sections.map((link) => (
              <motion.div
                key={link.name}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link
                  href={link.href}
                  onClick={() => handleNavigation(link.href, link.type)}
                  className="text-white transition-colors hover:text-black"
                >
                  {link.name}
                </Link>
              </motion.div>
            ))}
          </div>

          {/* Séparateur vertical */}
          <div className="h-6 w-px bg-white/20" />

          {/* Pages */}
          <div className="flex items-center gap-8">
            {navLinks.pages.map((link) => (
              <motion.div
                key={link.name}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link
                  href={link.href}
                  onClick={() => handleNavigation(link.href, link.type)}
                  className="text-white transition-colors hover:text-black"
                >
                  {link.name}
                </Link>
              </motion.div>
            ))}
          </div>

          <PrimaryButton className="bg-primary" pulsating={false} calendly>
            Démarrer un projet
          </PrimaryButton>
        </motion.div>

        {/* Mobile Menu Button - Ajout d'un background au bouton */}
        <motion.button
          className={`relative z-50 h-10 w-10 rounded-lg lg:hidden`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          animate={isMenuOpen ? "open" : "closed"}
        >
          <motion.span
            className="absolute left-2 top-4 h-0.5 w-6 bg-white"
            variants={{
              closed: { rotate: 0 },
              open: { rotate: 45, y: 0 },
            }}
          />
          <motion.span
            className="absolute left-2 top-6 h-0.5 w-6 bg-white"
            variants={{
              closed: { opacity: 1 },
              open: { opacity: 0 },
            }}
          />
          <motion.span
            className="absolute left-2 top-8 h-0.5 w-6 bg-white"
            variants={{
              closed: { rotate: 0 },
              open: { rotate: -45, y: -16 },
            }}
          />
        </motion.button>

        {/* Mobile Menu - Modification du background */}
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              className="fixed inset-0 top-16 bg-[#1F1B4B]"
              initial="closed"
              animate="open"
              exit="closed"
              variants={menuVariants}
            >
              <div className="flex h-[calc(100dvh_-_64px)] flex-col justify-between bg-black p-6">
                <motion.div className="flex flex-col gap-6">
                  {/* Sections */}
                  <div className="flex flex-col gap-6">
                    <motion.h2
                      variants={linkVariants}
                      className="text-sm font-semibold uppercase tracking-wider text-primary"
                    >
                      Sections
                    </motion.h2>
                    {navLinks.sections.map((link) => (
                      <motion.div
                        key={link.name}
                        variants={linkVariants}
                        whileHover={{ x: 10 }}
                      >
                        <Link
                          href={link.href}
                          onClick={() => handleNavigation(link.href, link.type)}
                          className="text-2xl text-white"
                        >
                          {link.name}
                        </Link>
                      </motion.div>
                    ))}
                  </div>

                  {/* Séparateur horizontal */}
                  <motion.div
                    variants={linkVariants}
                    className="my-4 h-px w-full bg-white/20"
                  />

                  {/* Pages */}
                  <div className="flex flex-col gap-6">
                    <motion.h2
                      variants={linkVariants}
                      className="text-sm font-semibold uppercase tracking-wider text-primary"
                    >
                      Pages
                    </motion.h2>
                    {navLinks.pages.map((link) => (
                      <motion.div
                        key={link.name}
                        variants={linkVariants}
                        whileHover={{ x: 10 }}
                      >
                        <Link
                          href={link.href}
                          onClick={() => handleNavigation(link.href, link.type)}
                          className="text-2xl text-white"
                        >
                          {link.name}
                        </Link>
                      </motion.div>
                    ))}
                  </div>
                </motion.div>

                <div className="flex flex-col items-center gap-6">
                  <p className="text-center text-white">
                    Prêt à lancer votre projet ? <br />
                    Réservez dès maintenant votre rendez-vous !
                  </p>

                  <PrimaryButton
                    className="bg-primary"
                    calendly={true}
                    pulsating={false}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <CalendarIcon />
                    Réserver un rendez-vous
                  </PrimaryButton>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
    </header>
  );
};

const CalendarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5em"
    height="1.5em"
    viewBox="0 0 15 15"
    className="fill-current"
  >
    <path
      fillRule="evenodd"
      d="M4.5 1a.5.5 0 0 1 .5.5V2h5v-.5a.5.5 0 0 1 1 0V2h1.5A1.5 1.5 0 0 1 14 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 1 12.5v-9A1.5 1.5 0 0 1 2.5 2H4v-.5a.5.5 0 0 1 .5-.5M10 3v.5a.5.5 0 0 0 1 0V3h1.5a.5.5 0 0 1 .5.5V5H2V3.5a.5.5 0 0 1 .5-.5H4v.5a.5.5 0 0 0 1 0V3zM2 6v6.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V6zm5 1.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0M9.5 7a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1m1.5.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0m.5 1.5a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1M9 9.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0M7.5 9a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1M5 9.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0M3.5 9a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1M3 11.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0m2.5-.5a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1m1.5.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0m2.5-.5a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1"
    />
  </svg>
);

export default Header;
